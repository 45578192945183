<template>
  <div class="dataM processManagement">
    <div class="content">
      <div class="title">
        <div>工步列表</div>
        <div class="tool">
          <!-- <upload @uploadFile="uploadFile" ref="upload"/> -->
          <upload
            ref="upload"
            templateName="工步列表导入模板"
            templateParam="processList"
            @handleSearch="handleSearch"
            v-premiSub="'工步管理_导入'"
          />
          <!-- <img src="@/assets/images/dataManage/batchdel.png" @click="comfirm('')"/> -->
          <el-input
            v-model="searchValue"
            style="width:220px"
            size="small"
            placeholder="请输入代号/名称"
            @keydown.enter.native="handleSearch(1)"
            >
            <i slot="suffix" class="el-input__icon el-icon-search" @click="handleSearch(1)"></i>
          </el-input>
        </div>
      </div>
        <el-table :data="tableData" header-cell-class-name="tableHeader" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column label="序号" prop="showId"/>
          <el-table-column prop="technologyCode" label="工步代号"/>
          <el-table-column prop="technologyName" label="工步名称"/>
          <el-table-column prop="countTimeMethod" label="排程条件">
            <template slot-scope="scope">
              {{scope.row.countTimeMethod === '按订单数量'?'默认':scope.row.countTimeMethod}}
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <img 
                src="@/assets/images/dataManage/edit.png" 
                style="margin-right:10px" 
                @click="handleEdit(scope.row)"
              />
              <img src="@/assets/images/dataManage/del.png"  @click="comfirm(scope.row)"/>
            </template>
          </el-table-column>
      </el-table>
      <pagination :total="total" :page.sync="page" :size.sync="size"  @getTableData="handleSearch"/>
    </div>
    <!-- 修改客户信息弹窗 -->
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      custom-class="dataM"
      width="690px"
      >
      <div class="editBox">
        <div class="item">
          <label>工步代号</label>
          <el-input v-model="editData.technologyCode"></el-input>
        </div>
        <div class="item required">
          <label>工步名称</label>
          <el-input v-model="editData.technologyName"></el-input>
        </div>
        <div class="item required">
          <label>排程条件</label>
          <el-select v-model="editData.countTimeMethod">
            <el-option label="默认" value="按订单数量"></el-option>
            <el-option label="按天数" value="按天数"></el-option>
            <el-option label="按比例" value="按比例"></el-option>
          </el-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="edit">确 定</el-button>
      </span>
    </el-dialog>
    <resultDialog 
      name="工步" 
      :resultVisible.sync="resultVisible" 
      :type="resultType" 
      :fileName="fileName"
      :resultMsg="resultMsg"
      @reUpload="reUpload"
    />
  </div>
</template>

<script>
const pagination = () => import('@/components/pagination')
const upload = () => import('@/components/upload/upload')
const resultDialog = () => import('@/components/upload/resultDialog')
import {getTechnology,delTechnology,editTechnology,importTechnology,delTechnologyBatch} from '@/api/data.js'
import { isComfirm } from '@/utils/data.js'
export default {
  name: "processManagement",
  components:{
    pagination,
    upload,
    resultDialog
  },
  data() {
    return {
      tableData:[],
      total:0,
      page:1,
      size:10,
      searchValue:'',
      dialogVisible:false,
      resultVisible:false,
      resultType:true,
      resultMsg:'',
      fileName:'',
      editData:{
        "id": "",
        "technologyCode": "",
        "technologyName": "",
        "countTimeMethod": ""
      },
      multipleSelection:[]
    };
  },
  watch:{
    size(){
      this.handleSearch(1)
    },
    dialogVisible(val){
      if(!val){
        Object.assign(this.editData, this.$options.data().editData);
      }
    }
  },
  activated(){
    this.handleSearch()
  },
  methods:{
    handleSelectionChange(val){
      this.multipleSelection = val.map(row => row.id);
    },
    //查询工艺列表
    handleSearch(page){
      page ? this.page = page : this.page = 1;
      let params = {
        page:this.page,
        size:this.size,
        searchValue:this.searchValue
      }
      getTechnology(params).then(res=>{
        if(res.code === 0){
          this.tableData = res.data.records
          this.total = res.data.total
        }else{
          this.$message.error(res.msg || '接口出错')
        }
      }).catch(()=>{
        this.$message.error('接口出错')
      })
    },
    handleEdit(row){
      this.dialogVisible = true;
      this.editData = {...row}
    },
    //编辑工艺
    edit(){
      let {id,technologyCode,technologyName,countTimeMethod} = this.editData
      if(!technologyName) return this.$message.error('请输入工步名称')
      if(!countTimeMethod) return this.$message.error('请输入排程条件')
      let params = {
        id,
        technologyCode,
        technologyName,
        countTimeMethod
      }
      editTechnology(params).then(res=>{
        if(res.code === 0){
          this.$message.success('操作成功')
          this.dialogVisible = false
          this.handleSearch()
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    },
    comfirm(row){
      if(!row && !this.multipleSelection.length){
        this.$message.warning('请选择要删除的数据')
        return
      }
      isComfirm(row,row ? this.del : this.batchdel)
    },
    //删除工艺
    del(row){
      delTechnology(row.id).then(res=>{
        if(res.code === 0){
          this.$message.success('删除成功')
          this.handleSearch()
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    },
    batchdel(){
      delTechnologyBatch(this.multipleSelection).then(res=>{
        if(res.code === 0){
          this.$message.success('删除成功')
          this.handleSearch()
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    },
    uploadFile(file){
      let formData = new FormData();
      formData.append('file',file.raw);
      this.fileName = file.name
      importTechnology(formData).then(res=>{
        this.resultMsg = res.msg
        if(res.code == 0){
          this.resultVisible = true
          this.resultType = true
          this.handleSearch(1)
        }else{
          this.resultVisible = true
          this.resultType = false
        }
      }).catch((error)=>{
        this.resultMsg = error.response?.data?.msg
        this.resultVisible = true
        this.resultType = false
      })
    },
    reUpload(){
      this.$refs.upload.reUpload()
    }
  }
};
</script>

<style lang="scss">
.processManagement{
  padding: 18px;
  color: #E8E8E8;
  text-align: left;
  .content{
    position: relative;
    background: #232323;
    min-height: calc(100vh - 280px);
    text-align: left;
    padding: 25px 35px 80px 35px;
    .tableHeader{
      background: #2C2C2C;
      color: #B9B9B9;
    }
    .title{
      font-size: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }
    img{
      width: 22px;
      height: 22px;
    }
    .tool{
      width: 300px;
      display: flex;
      align-items: center;
      img{
        margin: 0 15px;
        cursor: pointer;
        width: 22px;
        height: 22px;
      }
    }
    .colorBox{
      height:32px;
      width:32px;
      border-radius: 6px;
    }
  }
  .editBox{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .item{
      display: flex;
      flex-direction: column;
      margin-top: 15px;
      width: 45%;
       .el-input{
        width: 100%;
       }
    }
    .required{
      label:after {
        content:'*';
        color: red;
        margin-left:3px;
       }
    }
  }
}
</style>
