import { MessageBox } from "element-ui";

//删除二次确认弹窗
export const isComfirm = (data,callback) =>{
  MessageBox.confirm('确认后，内容将无法找回', data ? '确认删除这行内容?' : '确认批量删除内容?', {
    customClass:'dataM',
    dangerouslyUseHTMLString: true,
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    callback(data)
  }).catch(()=>{
    
  })
}
// // 用于判断多个时间段是否有冲突，同时考虑跨天情况
export function areTimeRangesConflicting(timeSlots) {
  const len = timeSlots.length;
  for (let i = 0; i < len; i++) {
    const slot1 = timeSlots[i];
    const start1 = convertTimeToMinutes(slot1.startTime);
    const end1 = convertTimeToMinutes(slot1.endTime);
  
    const start1NextDay = (start1 >= end1) ? start1 - 24 * 60 : start1;
    const end1NextDay = (end1 <= start1) ? end1 + 24 * 60 : end1;

    for (let j = 0; j < len; j++) {
      if (i === j) continue;
      
      const slot2 = timeSlots[j];
      const start2 = convertTimeToMinutes(slot2.startTime);
      const end2 = convertTimeToMinutes(slot2.endTime);
      
      if (
        (start2 >= start1 && start2 < end1NextDay) ||
        (end2 > start1 && end2 <= end1NextDay) ||
        (start2 <= start1NextDay && end2 >= end1NextDay)
      ) {
        return true; // 时间段冲突
      }
    }
  }
  
  return false; // 没有时间段冲突
}

function convertTimeToMinutes(time) {
  const [hours, minutes] = time.split(':').map(Number);
  return hours * 60 + minutes;
}

